import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
import './index.css';
import App from './App';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD_INSS3QqRK3NbyjbIcY5VN1zfnqWPJec',
  authDomain: 'nielson-recipes.firebaseapp.com',
  projectId: 'nielson-recipes',
  storageBucket: 'nielson-recipes.appspot.com',
  messagingSenderId: '898449527069',
  appId: '1:898449527069:web:1c075dcd5d5b04c7a051f4',
  measurementId: 'G-49C0C08WGH',
};

// Initialize Firebase
initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
