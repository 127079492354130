export const getCategoryList = () => {
  return [
    'dinner',
    'lunch',
    'breakfast',
    'snack',
    'drink',
    'appetizer',
    'sauce',
    'side',
    'fun',
  ];
};

export const getCategoryName = (id: string) => {
  switch (id) {
    case 'dinner':
      return 'Dinner';
    case 'lunch':
      return 'Lunch';
    case 'breakfast':
      return 'Breakfast';
    case 'snack':
      return 'Desserts / Snacks';
    case 'drink':
      return 'Drinks';
    case 'appetizer':
      return 'Appetizers';
    case 'sauce':
      return 'Sauces';
    case 'side':
      return 'Side Dishes';
    case 'fun':
      return 'Fun Dishes';
    default:
      return '';
  }
};
