import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../components/Button';
import IconButton from '../components/IconButton';
import Spacer from '../components/Spacer';
import useUser from '../hooks/useUser';
import Image from '../components/Image';

const TwoColumn = styled.div`
  display: flex;
  gap: 50px;
`;
const Error = styled.div`
  background-color: #ff99cc;
  border: 2px solid #aa0033;
  padding: 20px;
`;
const FormField = styled.div`
  padding-bottom: 10px;
  padding-top: 6px;
`;
const FormHeader = styled.div`
  padding-bottom: 8px;
`;
const FormValue = styled.div``;
const ButtonSection = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 10px;
  padding-top: 6px;
`;
const HiddenInput = styled.input`
  visibility: hidden;
`;
const Dropzone = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  width: 280px;
`;
const TextInput = styled.input`
  height: 24px;
  padding: 4px;
  width: 268px;
`;
const TextAreaInput = styled.textarea`
  height: 200px;
  padding: 4px;
  width: 600px;
`;
const SelectInput = styled.select`
  height: 35px;
  padding: 4px;
  width: 280px;
`;
const EditIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const AddRecipePage = () => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [newRecipe, setNewRecipe] = useState({
    name: '',
    directions: '',
    category: 'dinner',
    keywords: '',
    ingredients: '',
    totalTime: 0,
    yield: '',
  });
  const [recipeImage, setRecipeImage] = useState<File | null>(null);

  const { user } = useUser();
  const navigate = useNavigate();

  const addRecipe = async () => {
    setSaving(true);
    const authtoken = user && (await user.getIdToken());
    if (!authtoken) return;

    const headers: HeadersInit = {
      authtoken,
      'Content-Type': 'application/json',
    };
    fetch('/api/recipes', {
      headers,
      method: 'POST',
      body: JSON.stringify(newRecipe),
    })
      .then((response) => response.json())
      .then((data) => {
        const formData = new FormData();
        if (recipeImage) {
          formData.append('recipeImage', recipeImage);
          delete headers['Content-Type'];
          return fetch(`/api/recipes/${data._id}/uploadImage`, {
            headers,
            method: 'POST',
            body: formData,
          });
        }
      })
      .then(() => {
        navigate('/manage');
      })
      .catch((e) => {
        setError(e.message);
        setSaving(false);
      });
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files && files[0]) {
      setRecipeImage(files[0]);
    }
  };

  return (
    <>
      <h2>Add Recipe</h2>
      <Spacer height={8} />
      {error && <Error>{error}</Error>}
      <TwoColumn>
        <FormField>
          <FormHeader>Name</FormHeader>
          <FormValue>
            <TextInput
              type="text"
              value={newRecipe.name}
              onChange={(e) =>
                setNewRecipe({ ...newRecipe, name: e.target.value })
              }
            />
          </FormValue>
        </FormField>
        <FormField>
          <FormHeader>Category</FormHeader>
          <FormValue>
            <SelectInput
              value={newRecipe.category}
              onChange={(e) =>
                setNewRecipe({ ...newRecipe, category: e.target.value })
              }
            >
              <option value="dinner">Dinner</option>
              <option value="lunch">Lunch</option>
              <option value="breakfast">Breakfast</option>
              <option value="snack">Snack / Dessert</option>
              <option value="drink">Drinks</option>
              <option value="appetizer">Appetizers</option>
              <option value="sauce">Sauces</option>
              <option value="side">Side Dishes</option>
              <option value="fun">Fun Dishes</option>
            </SelectInput>
          </FormValue>
        </FormField>
      </TwoColumn>
      <FormField>
        <FormHeader>Ingredients</FormHeader>
        <FormValue>
          <TextAreaInput
            value={newRecipe.ingredients}
            onChange={(e) =>
              setNewRecipe({ ...newRecipe, ingredients: e.target.value })
            }
          />
        </FormValue>
      </FormField>
      <FormField>
        <FormHeader>Directions</FormHeader>
        <FormValue>
          <TextAreaInput
            value={newRecipe.directions}
            onChange={(e) =>
              setNewRecipe({ ...newRecipe, directions: e.target.value })
            }
          />
        </FormValue>
      </FormField>
      <TwoColumn>
        <div>
          <FormField>
            <FormHeader>Total Time</FormHeader>
            <FormValue>
              <TextInput
                type="text"
                value={newRecipe.totalTime}
                onChange={(e) => {
                  const timeValue = parseInt(e.target.value);
                  if (isNaN(timeValue)) {
                    setError('Total Time must be a number');
                  } else {
                    setNewRecipe({ ...newRecipe, totalTime: timeValue });
                  }
                }}
              />
            </FormValue>
          </FormField>
          <FormField>
            <FormHeader>Yield</FormHeader>
            <FormValue>
              <TextInput
                type="text"
                value={newRecipe.yield}
                onChange={(e) =>
                  setNewRecipe({ ...newRecipe, yield: e.target.value })
                }
              />
            </FormValue>
          </FormField>
          <FormField>
            <FormHeader>Keywords</FormHeader>
            <FormValue>
              <TextInput
                type="text"
                value={newRecipe.keywords}
                onChange={(e) =>
                  setNewRecipe({ ...newRecipe, keywords: e.target.value })
                }
              />
            </FormValue>
          </FormField>
        </div>
        <FormField>
          <FormHeader>Image</FormHeader>
          <FormValue>
            <Dropzone
              onClick={() => document.getElementById('dropzone-file')?.click()}
            >
              {recipeImage ? (
                <img
                  alt={newRecipe.name}
                  src={URL.createObjectURL(recipeImage)}
                  width={280}
                />
              ) : (
                <Image
                  recipe={newRecipe}
                  style={{ filter: 'invert(1)', 'margin-top': '50px' }}
                  width={150}
                />
              )}
              <EditIcon>
                <IconButton
                  icon={recipeImage ? 'pencil' : 'add'}
                  size="large"
                />
              </EditIcon>
            </Dropzone>
          </FormValue>
          <HiddenInput
            id="dropzone-file"
            type="file"
            accept=".jpg, .jpeg"
            onChange={handleFileInput}
          />
        </FormField>
      </TwoColumn>
      <ButtonSection>
        <Button disabled={saving} onClick={addRecipe}>
          Save
        </Button>
        <Button onClick={() => navigate('/manage')}>Cancel</Button>
      </ButtonSection>
    </>
  );
};

export default AddRecipePage;
