import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { getCategoryList, getCategoryName } from '../utils/categories';

const Container = styled.div`
  background: #b0d9f9;
  color: #272b46;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
`;
const Category = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 8px;
  padding: 8px;
  text-align: center;
  align-items: center;
  width: 150px;

  :hover {
    box-shadow: 0 0 3px 2px #272b46;
  }
`;

const HomePage = () => {
  const navigate = useNavigate();

  const categories = useMemo(() => {
    return getCategoryList();
  }, []);

  return (
    <Container>
      {categories.map((category) => {
        return (
          <Category onClick={() => navigate(`/category/${category}`)}>
            <img
              alt={category}
              src={`/images/${category}.jpg`}
              height="150px"
              width="150px"
            />
            <span>{getCategoryName(category)}</span>
          </Category>
        );
      })}
    </Container>
  );
};

export default HomePage;
