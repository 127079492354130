import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from './Button';

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const SearchInput = styled.input`
  height: 24px;
  width: 200px;
  padding: 4px;
`;

const Search = () => {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  return (
    <Container>
      <SearchInput
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && navigate(`/search?q=${query}`)}
      />
      <Button icon="search" onClick={() => navigate(`/search?q=${query}`)}>
        Search
      </Button>
    </Container>
  );
};

export default Search;
