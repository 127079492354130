import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import './App.css';
import SiteHeader from './components/SiteHeader';
import AddRecipePage from './pages/AddRecipePage';
import CategoryPage from './pages/CategoryPage';
import EditRecipePage from './pages/EditRecipePage';
import ManageRecipesPage from './pages/ManageRecipesPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RecipePage from './pages/RecipePage';
import SearchResultsPage from './pages/SearchResultsPage';

const Page = styled.div`
  position: relative;
  margin: 0 auto;
  width: 950px;
`;

function App() {
  return (
    <BrowserRouter basename="/recipes">
      <Page>
        <header className="App-header">
          <SiteHeader />
        </header>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/add" element={<AddRecipePage />} />
          <Route path="/category/:id" element={<CategoryPage />} />
          <Route path="/edit/:recipeId" element={<EditRecipePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/manage" element={<ManageRecipesPage />} />
          <Route path="/recipe/:recipeId" element={<RecipePage />} />
          <Route path="/search" element={<SearchResultsPage />} />
        </Routes>
      </Page>
    </BrowserRouter>
  );
}

export default App;
