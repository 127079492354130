import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';

import useUser from '../hooks/useUser';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const { user } = useUser();
  const navigate = useNavigate();
  const auth = getAuth();

  const login = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => navigate('/'))
      .catch((e) => setError(e.message));
  };

  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => navigate('/'))
      .catch((e) => setError(e.message));
  };

  return (
    <div>
      <h1>Login Page</h1>
      {error && <div>{error}</div>}
      <div>Hello {user?.email}</div>
      <div>Email</div>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <div>Password</div>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <div>
        <button onClick={login}>Login</button>
      </div>
      <div>
        <button onClick={loginWithGoogle}>Login with Google</button>
      </div>
      <div>
        <button onClick={() => signOut(getAuth())}>Sign Out</button>
      </div>
    </div>
  );
};

export default LoginPage;
