import React, { ReactNode } from 'react';
import styled from 'styled-components';

const IntroDiv = styled.div`
  background: #b0d9f9;
  color: #6570b6;
`;

type IntroProps = {
  children?: ReactNode;
  className?: string;
};

const Intro = ({ children, className }: IntroProps) => {
  return <IntroDiv className={className}>{children}</IntroDiv>;
};

export default Intro;
