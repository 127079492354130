import { useEffect, useMemo, useState } from 'react';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';

const useUser = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const isAdmin = useMemo(
    () =>
      ['jordancnielson@gmail.com', 'nielsonmelanie@gmail.com'].includes(
        user?.email ?? ''
      ),
    [user]
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return { isAdmin, userLoading: loading, user };
};

export default useUser;
