import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';

import { getRecipe } from '../api/recipes';
import Button from '../components/Button';
import IconButton from '../components/IconButton';
import Image from '../components/Image';
import Spacer from '../components/Spacer';
import Spinner from '../components/Spinner';
import useUser from '../hooks/useUser';

const TwoColumn = styled.div`
  display: flex;
  gap: 50px;
`;
const Error = styled.div`
  background-color: #ff99cc;
  border: 2px solid #aa0033;
  padding: 20px;
`;
const FormField = styled.div`
  padding-bottom: 10px;
  padding-top: 6px;
`;
const FormHeader = styled.div`
  padding-bottom: 8px;
`;
const FormValue = styled.div``;
const ButtonSection = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 10px;
  padding-top: 6px;
`;
const HiddenInput = styled.input`
  visibility: hidden;
`;
const Dropzone = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  width: 280px;
`;
const TextInput = styled.input`
  height: 24px;
  padding: 4px;
  width: 268px;
`;
const TextAreaInput = styled.textarea`
  height: 200px;
  padding: 4px;
  width: 600px;
`;
const SelectInput = styled.select`
  height: 35px;
  padding: 4px;
  width: 280px;
`;
const EditIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const EditRecipePage = () => {
  const { recipeId } = useParams();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [recipe, setRecipe] = useState({
    name: '',
    directions: '',
    category: 'dinner',
    keywords: '',
    ingredients: '',
    totalTime: 0,
    yield: '',
  });
  const [originalImageId, setOriginalImageId] = useState<string | undefined>(
    undefined
  );
  const [recipeImage, setRecipeImage] = useState<File | null>(null);

  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (recipeId) {
      getRecipe(recipeId).then((recipe) => {
        setOriginalImageId(recipe.imageId);
        setRecipe(
          _.pick(recipe, [
            'name',
            'directions',
            'category',
            'keywords',
            'ingredients',
            'totalTime',
            'yield',
          ])
        );
      });
    }
  }, [recipeId]);

  const updateRecipe = async () => {
    setSaving(true);
    const authtoken = user && (await user.getIdToken());
    if (!authtoken) return;

    const headers: HeadersInit = {
      authtoken,
      'Content-Type': 'application/json',
    };
    fetch(`/api/recipes/${recipeId}`, {
      headers,
      method: 'PUT',
      body: JSON.stringify(recipe),
    })
      .then((response) => response.json())
      .then(() => {
        const formData = new FormData();
        if (recipeImage) {
          formData.append('recipeImage', recipeImage);
          delete headers['Content-Type'];
          return fetch(`/api/recipes/${recipeId}/uploadImage`, {
            headers,
            method: 'POST',
            body: formData,
          });
        }
      })
      .then(() => {
        navigate('/manage');
      })
      .catch((e) => {
        setError(e.message);
        setSaving(false);
      });
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files && files[0]) {
      setRecipeImage(files[0]);
    }
  };

  if (!recipe) {
    return <Spinner />;
  }

  return (
    <>
      <h2>Edit Recipe</h2>
      <Spacer height={8} />
      {error && <Error>{error}</Error>}
      <TwoColumn>
        <FormField>
          <FormHeader>Name</FormHeader>
          <FormValue>
            <TextInput
              type="text"
              value={recipe.name}
              onChange={(e) => setRecipe({ ...recipe, name: e.target.value })}
            />
          </FormValue>
        </FormField>
        <FormField>
          <FormHeader>Category</FormHeader>
          <FormValue>
            <SelectInput
              value={recipe.category}
              onChange={(e) =>
                setRecipe({ ...recipe, category: e.target.value })
              }
            >
              <option value="dinner">Dinner</option>
              <option value="lunch">Lunch</option>
              <option value="breakfast">Breakfast</option>
              <option value="snack">Snack / Dessert</option>
              <option value="drink">Drinks</option>
              <option value="appetizer">Appetizers</option>
              <option value="sauce">Sauces</option>
              <option value="side">Side Dishes</option>
              <option value="fun">Fun Dishes</option>
            </SelectInput>
          </FormValue>
        </FormField>
      </TwoColumn>
      <FormField>
        <FormHeader>Ingredients</FormHeader>
        <FormValue>
          <TextAreaInput
            value={recipe.ingredients}
            onChange={(e) =>
              setRecipe({ ...recipe, ingredients: e.target.value })
            }
          />
        </FormValue>
      </FormField>
      <FormField>
        <FormHeader>Directions</FormHeader>
        <FormValue>
          <TextAreaInput
            value={recipe.directions}
            onChange={(e) =>
              setRecipe({ ...recipe, directions: e.target.value })
            }
          />
        </FormValue>
      </FormField>
      <TwoColumn>
        <div>
          <FormField>
            <FormHeader>Total Time</FormHeader>
            <FormValue>
              <TextInput
                type="text"
                value={recipe.totalTime}
                onChange={(e) => {
                  const timeValue = parseInt(e.target.value);
                  if (isNaN(timeValue)) {
                    setError('Total Time must be a number');
                  } else {
                    setRecipe({ ...recipe, totalTime: timeValue });
                  }
                }}
              />
            </FormValue>
          </FormField>
          <FormField>
            <FormHeader>Yield</FormHeader>
            <FormValue>
              <TextInput
                type="text"
                value={recipe.yield}
                onChange={(e) =>
                  setRecipe({ ...recipe, yield: e.target.value })
                }
              />
            </FormValue>
          </FormField>
          <FormField>
            <FormHeader>Keywords</FormHeader>
            <FormValue>
              <TextInput
                type="text"
                value={recipe.keywords}
                onChange={(e) =>
                  setRecipe({ ...recipe, keywords: e.target.value })
                }
              />
            </FormValue>
          </FormField>
        </div>
        <FormField>
          <FormHeader>Image</FormHeader>
          <FormValue>
            <Dropzone
              onClick={() => document.getElementById('dropzone-file')?.click()}
            >
              {recipeImage ? (
                <img
                  alt={recipe.name}
                  src={URL.createObjectURL(recipeImage)}
                  width={280}
                />
              ) : (
                <Image
                  recipe={{ ...recipe, imageId: originalImageId }}
                  style={
                    originalImageId
                      ? undefined
                      : { filter: 'invert(1)', 'margin-top': '50px' }
                  }
                  width={originalImageId ? 280 : 150}
                />
              )}
              <EditIcon>
                <IconButton
                  icon={originalImageId || recipeImage ? 'pencil' : 'add'}
                  size="large"
                />
              </EditIcon>
            </Dropzone>
          </FormValue>
          <HiddenInput
            id="dropzone-file"
            type="file"
            accept=".jpg, .jpeg"
            onChange={handleFileInput}
          />
        </FormField>
      </TwoColumn>
      <ButtonSection>
        <Button disabled={saving} onClick={updateRecipe}>
          Save
        </Button>
        <Button onClick={() => navigate('/manage')}>Cancel</Button>
      </ButtonSection>
    </>
  );
};

export default EditRecipePage;
