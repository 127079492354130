import React from 'react';

import type { Recipe } from '../types/recipes';

const Image = ({
  recipe,
  ...imageProps
}: {
  recipe: Partial<Recipe> & { name: string };
  width?: number;
  height?: number;
  style?: object;
}) => {
  if (!recipe.imageId) {
    return (
      // eslint-disable-next-line jsx-a11y/img-redundant-alt
      <img
        src={'/icons/no_photo.svg'}
        alt="no photo"
        style={{ filter: 'invert(1)' }}
        {...imageProps}
      />
    );
  }

  return (
    <img
      src={`https://storage.googleapis.com/nielson-recipes-images/${recipe.imageId}.jpg`}
      alt={recipe.name}
      {...imageProps}
    />
  );
};

export default Image;
