import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import Button from './Button';
import Search from './Search';
import useUser from '../hooks/useUser';

const HeaderDiv = styled.div`
  display: flex;
  height: 102px;
  justify-content: space-between;
`;
const Logo = styled.h1`
  padding-top: 20px;
  width: 390px;
  font-weight: normal;
  font-size: 38px;
  line-height: 52px;
  text-align: center;
  color: #f6f6de;
  cursor: pointer;
`;
const MenuDiv = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 16px;
  padding-right: 40px;
  padding-top: 24px;
  height: 52px;
`;

const SiteHeader = () => {
  const navigate = useNavigate();
  const { isAdmin, user } = useUser();
  const provider = new GoogleAuthProvider();

  return (
    <HeaderDiv>
      <Logo onClick={() => navigate('/')}>Nielson Recipes</Logo>
      <MenuDiv>
        <Search />
        {isAdmin && <Button onClick={() => navigate('/manage')}>Manage</Button>}
        {!user && (
          <Button onClick={() => signInWithPopup(getAuth(), provider)}>
            Sign In
          </Button>
        )}
      </MenuDiv>
    </HeaderDiv>
  );
};

export default SiteHeader;
